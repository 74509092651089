import Vue from 'vue';
import VueRouter from 'vue-router';
import OculisticaForm from '../components/OculisticaForm.vue';
import Research from '../views/Research.vue';
import About from '../views/About.vue';
import Contact from '../views/Contact.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'OculisticaForm',
    component: OculisticaForm,
  },
  {
    path: '/research',
    name: 'Research',
    component: Research,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
