<style scoped>
  .card{
    max-width: 750px;;
    border: 1px solid #b5bccc;
    background-color: white !important;
  }
  .card-title {
    color: black;
    padding: 10px;
    border-bottom: 1px solid #21252e;
  }
  .card-body{
    padding: 10px;
    font-family: Verdana !important;
  }
  .card-deck{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right:10px;
  }
  .card-text {
    margin: 20px;
    color: #21252e;
    font-size: 18px;
    font-family:sans-serif;
  }
  .btn-link {
    text-align: left;
    margin: 0px;
    padding: 0px;
  }

  #calculation-block{
    padding: 10px;
    border: 1px solid #21252e;
  }
  @media only screen and (max-width: 700px) {
    .card-body{
      padding: 0px;
    }
    .container{
      padding: 10px;
    }
  }
</style>
<template>
  <div class="research">
    <b-container align="center">
        <b-card  body-bg-variant="light" header-text-variant="red">
          <b-card-text align="left">
              <div style="margin-top: 10px; margin-bottom: 10px">
                This Research Section is intended to make it easy for you
                to use and study the QST formula. Downloading and filling in
                the appropriate predefined Excel spreadsheet will allow you to perform:
                <ul style="margin-top: 0px; margin-bottom: 0px;">
                  <li>HQST Multiple Calculation</li>
                  <li>HQST Expected Rx by back-calculation (for scientific analysis)</li>
                  <li>HQST Optimization of your pACD for a given IOL (Needs at least 50 eyes)</li>
                </ul>
                None of the submitted data is saved.
              </div>
              <div id="calculation-block">
                1. Download the desired sample excel (.xlsx) files
                <ul>
                  <li><b-button variant="link" @click="getIOLExample"
                      style="margin: 0px; padding 0px;">
                      Multiple-Calculation.xlsx</b-button></li>
                  <li><b-button variant="link" @click="getExpectedRxExample">
                      Expected-Rx.xlsx</b-button></li>
                  <li>
                    <b-button variant="link" @click="getIOLPostLasikExample">
                      Multiple-Calculation-PostLasik.xlsx</b-button></li>
                  <li>
                    <b-button variant="link" @click="getExpectedRxPostLasikExample">
                      Expected-Rx-PostLasik.xlsx</b-button></li>
                  <li><b-button variant="link" @click="getHQSTOptimizationExample">
                      Optimization.xlsx</b-button></li>
                </ul>
                2. Select the desired calculation
                  <b-form-radio-group  align="center" style="margin: 10px;"
                    v-model="computation" :options="options_computation">
                  </b-form-radio-group>
                3. Upload the chosen excel file
                <div style="display: flex; justify-content: center; margin: 10px; padding: 5px;">
                  <b-form-file v-model="file" :state="Boolean(file)"
                    placeholder="Choose file"
                    accept=".xlsx">
                  </b-form-file>
                </div>
                4. Press Calculate and wait for the results.
                <div style="display: flex; justify-content: center; margin: 5px;">
                  <b-button variant="success" @click="compute">Calculate</b-button>
                </div>
              </div>
          </b-card-text>
        </b-card>
    </b-container>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      file: null,
      // eslint-disable-next-line
      options_computation: ['Multiple-Calculation', 'Expected-Rx', 'Multiple-Calculation-PostLasik', 'Expected-Rx-PostLasik', 'Optimization'],
      computation: 'Multiple-Calculation',
      processing: false,
      // url: 'http://localhost:8000',
      url: '',
    };
  },
  methods: {
    computeIOL() {
      //  https://stackoverflow.com/questions/61722139/how-to-upload-multiple-files-using-bootstrap-vue
      this.processing = true;
      const formData = new FormData();
      formData.append('file', this.file);
      axios({
        method: 'post',
        url: `${this.url}/computeIOL`,
        responseType: 'blob', // important
        data: formData,
      })
        .then((response) => {
          this.downloadFile(response, 'Multiple-Calculation-Results.xlsx');
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          alert('Error: The uploaded file does not have the same structure as the Multiple-Calculation.xlsx one. Please download the sample file and try again.');
        });
    },
    computeIOLPostLasik() {
      //  https://stackoverflow.com/questions/61722139/how-to-upload-multiple-files-using-bootstrap-vue
      this.processing = true;
      const formData = new FormData();
      formData.append('file', this.file);
      axios({
        method: 'post',
        url: `${this.url}/computeIOLPostLasik`,
        responseType: 'blob', // important
        data: formData,
      })
        .then((response) => {
          this.downloadFile(response, 'Multiple-Calculation-PostLasik-Results.xlsx');
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          alert('Error: The uploaded file does not have the same structure as the Multiple-Calculation-PostLasik.xlsx one. Please download the sample file and try again.');
        });
    },
    computeExpectedRx() {
      //  https://stackoverflow.com/questions/61722139/how-to-upload-multiple-files-using-bootstrap-vue
      this.processing = true;
      const formData = new FormData();
      formData.append('file', this.file);
      axios({
        method: 'post',
        url: `${this.url}/computeExpectedRx`,
        responseType: 'blob', // important
        data: formData,
      })
        .then((response) => {
          this.downloadFile(response, 'Expected-Rx-Results.xlsx');
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          alert('Error: The uploaded file does not have the same structure as the Expected-Rx.xlsx one. Please download the sample file and try again.');
        });
    },
    computeExpectedRxPostLasik() {
      //  https://stackoverflow.com/questions/61722139/how-to-upload-multiple-files-using-bootstrap-vue
      this.processing = true;
      const formData = new FormData();
      formData.append('file', this.file);
      axios({
        method: 'post',
        url: `${this.url}/computeExpectedRxPostLasik`,
        responseType: 'blob', // important
        data: formData,
      })
        .then((response) => {
          this.downloadFile(response, 'Expected-Rx-PostLasik-Results.xlsx');
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          alert('Error: The uploaded file does not have the same structure as the Expected-Rx-PostLasik.xlsx one. Please download the sample file and try again.');
        });
    },
    computeHQSTOptimization() {
      //  https://stackoverflow.com/questions/61722139/how-to-upload-multiple-files-using-bootstrap-vue
      this.processing = true;
      const formData = new FormData();
      formData.append('file', this.file);
      axios({
        method: 'post',
        url: `${this.url}/computeHQSTOptimization`,
        responseType: 'blob', // important
        data: formData,
      })
        .then((response) => {
          this.downloadFile(response, 'Optimization-Results.xlsx');
          this.processing = false;
        })
        .catch((e) => {
          console.log(e);
          this.processing = false;
          alert('Error: The uploaded file does not have the same structure as the Optimization.xlsx one. Please download the sample file and try again.');
        });
    },
    compute() {
      if (this.processing === false) {
        if (this.computation === this.options_computation[0]) { // IOL
          this.computeIOL();
        } else if (this.computation === this.options_computation[1]) { // ExpectedRx
          this.computeExpectedRx();
        } else if (this.computation === this.options_computation[2]) { // IOL PostLasik
          this.computeIOLPostLasik();
        } else if (this.computation === this.options_computation[3]) { // ExpectedRx PostLasik
          this.computeExpectedRxPostLasik();
        } else {
          this.computeHQSTOptimization();
        }
      }
    },
    downloadFile(response, filename) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },
    getIOLExample() {
      axios({
        method: 'get',
        responseType: 'blob', // important
        url: `${this.url}/getIOLExample`,
      })
        .then((response) => {
          this.downloadFile(response, 'Multiple-Calculation.xlsx');
          [this.computation] = this.options_computation;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getIOLPostLasikExample() {
      axios({
        method: 'get',
        responseType: 'blob', // important
        url: `${this.url}/getIOLPostLasikExample`,
      })
        .then((response) => {
          this.downloadFile(response, 'Multiple-Calculation-PostLasik.xlsx');
          [, , this.computation] = this.options_computation;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getExpectedRxExample() {
      axios({
        method: 'get',
        responseType: 'blob', // important
        url: `${this.url}/getExpectedRxExample`,
      })
        .then((response) => {
          this.downloadFile(response, 'Expected-Rx.xlsx');
          [, this.computation] = this.options_computation;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getExpectedRxPostLasikExample() {
      axios({
        method: 'get',
        responseType: 'blob', // important
        url: `${this.url}/getExpectedRxPostLasikExample`,
      })
        .then((response) => {
          this.downloadFile(response, 'Expected-Rx-PostLasik.xlsx');
          [, , , this.computation] = this.options_computation;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getHQSTOptimizationExample() {
      axios({
        method: 'get',
        responseType: 'blob', // important
        url: `${this.url}/getHQSTOptimizationExample`,
      })
        .then((response) => {
          this.downloadFile(response, 'Optimization.xlsx');
          [, , , , this.computation] = this.options_computation;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getWebsiteInfo() {
      axios({
        method: 'get',
        responseType: 'blob', // important
        url: `${this.url}/getWebsiteInfo`,
      })
        .then((response) => {
          this.downloadFile(response, 'WebsiteInfo.xlsx');
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
