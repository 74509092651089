<style scoped>
  label {
    margin: 5px 0px 5px 0px;
  }
  .input-group-text , .form-control , select, body{
    color: #21252e !important;
  }
  select {
    text-align: center;
  }
  input[type="number"] {
    text-align: center;
  }
  input[type="text"] {
    text-align: center;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:read-only {
    background-color: white;
  }
  .card{
    border: 1px solid #21252e;
    background-color: white !important;
  }
  .card-deck {
     margin-top: 0px;
     margin-bottom: 0px;
  }
  /*.bg-dark{
    background-color: #F8F9FA !important;
  }*/
  .card-body{
    padding: 8px 15px 8px 15px;
    font-family: Verdana !important;
  }
  .btn{
    font-size:17px !important;
  }
  h2 {
    color: #21252e;
    font-size: 30px;
    font-family: serif;
  }
  h3, p{
    color: #21252e;
    font-size: 17px;
    font-family:Arial, Helvetica, sans-serif;
  }
  h4{
    color: white;
    font-size: 22px;
    font-family: sans-serif;
  }
  .custom-position{
    top: -5px;
    right: 3px;
    padding: 0px 3px 0px 0px;
    position: relative;
    font-size: 15px;
    font-family: sans-serif;
  }
  /deep/ .modal-custom {
    /* https://stackoverflow.com/questions/61007101/how-to-overwrite-the-vue-bootstrap-styles-in-b-modal */
    border-radius: 2.3rem;
  }
  #content{
    max-width: 85%;
  }
  .tables{
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
  }
  .tables>.column-1 {
      flex: 1 1 auto;
      margin: 0px 0px 0px 0px;
  }
  .tables>.column-2 {
    flex: 1 1 auto;
    margin: 0px 0px 0px 10px;
  }
  .tables>.column-2>.iols-svg {
    display: flex;
    justify-content: center;
  }
  #first-line {
    padding: 0px 0px 8px 0px;
    margin: 0px -10px 0px -10px;
    display: flex;
    flex-direction: row;
  }
  #first-line > div {
    display: flex;
    flex: 1 1 auto;
    margin: 5px 10px 5px 10px;
    justify-content: center;
    height: 38px; /*calc(1.5rem + 0.75rem + 2px);*/
  }

  /*.form-control.is-invalid{
    border-color: yellow;
  }*/

  @media only screen and (max-width: 700px) {
    #first-line{
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 1600px) {
    #content{
      max-width: 100%;
    }
    .tables{
      display: flex;
      flex-direction: column;
    }
    .tables>.column-2 {
      flex: 1 1 auto;
      margin: 0px;
    }
    .container{
      padding: 10px;
    }
}
</style>
<template>
  <div>
    <b-container class="mb-3 justify-content-center" style="max-width: 740px;">
      <h4 style="margin: 0px;">
        This Website is providing the Services of calculating
        the IOL power using the Hoffer
        <span class="custom-position">&#174;</span>
        QST Formula and the Naeser/Savini Toric Calculator.
      </h4>
    </b-container>
    <b-container id="content" class="justify-content-center">
      <b-card
        title=""
        body-bg-variant="default"
        align="left"
      >
        <b-form @submit="onSubmit"
          @reset="onReset" v-if="show" autocomplete="off">
          <div id="first-line">
            <div>
              <b-form-input  id="input-Surgeon"
                  v-model="input_Surgeon" placeholder="Surgeon"
                  type="text" size="md">
                </b-form-input>
            </div>
            <div>
              <b-form-input  id="input-PatientName"
                v-model="input_PatientName" placeholder="Patient"
                type="text" required size="md">
              </b-form-input>
            </div>
            <div>
              <b-form-radio-group  id="input-Gender"
                v-model="input_Gender" buttons name="input-radio-Gender"
                :options="options_Gender" required v-on:change="buttonResetClick"
                button-variant="outline-primary">
              </b-form-radio-group>
            </div>
            <div>
              <b-input-group class="mb-3" prepend="Index">
                <!--<b-form-input  value=1.3375 readonly>1.3375</b-form-input>-->
                <b-form-select id="input-index" v-model="input_index"
                  size="md" type="number" :options="options_index"></b-form-select>
              </b-input-group>
            </div>
          </div>
          <!--Input Right Eye , Left Eye -->
          <b-card-group deck>
              <!-- Right Eye -->
              <b-card body-bg-variant="default">
                <div style="display: flex; flex-direction: row; justify-content: space-around;">
                  <div>
                    <h2 class="text-center">OD Right</h2>
                  </div>
                  <div class="mt-1" v-if="option_calculation_right_postlasik==false">
                    <!--RightEye SE/Toric Calculation -->
                    <b-form-radio-group  id="input-caculation-righteye" size="sm"
                      v-model="input_calculation_righteye"
                      buttons name="input-radio-caculation-righteye"
                      :options="options_calculation_choice" required
                      button-variant="outline-primary"
                      v-on:change="rightCalculationChoiceChanged()">
                    </b-form-radio-group>
                  </div>
                  <div class="mt-1">
                    <b-form-checkbox size="sm"
                      v-model="option_calculation_right_postlasik"
                      v-on:change="rightCalculationChoicePostLasikChanged()"
                      button button-variant="outline-primary">
                      Post myopic LASIK/PRK
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="mt-1 mb-1">
                  <div  style="color: black;" class="mb-2"
                    v-if="option_calculation_right_postlasik==true">
                  </div>
                  <div v-if="option_calculation_right_postlasik==true"
                    style="color: black; background-color: #d0e1f9;
                    padding: 2px 5px 2px 5px;">
                    <span style="display: flex; justify-content: center;
                        font-family: serif;">OPTIONAL</span>
                      <div class="ml-1 mr-1 mb-1"
                      style="padding: 2px 5px 2px 5px;">
                      <span>Pre LASIK/PRK data</span>
                      <b-input-group class="mt-2 mb-2"
                            prepend="K1pre" append="D">
                          <b-form-input id="input-right-K1pre" type="number"
                          v-model.number="input_right_K1pre" step="any" min=0
                          max=500 size="md"></b-form-input>
                      </b-input-group>
                      <b-input-group class="mt-2 mb-2"
                            prepend="K2pre" append="D">
                          <b-form-input id="input-right-K2pre" type="number"
                          v-model.number="input_right_K2pre" step="any" min=0
                          max=500 size="md"></b-form-input>
                      </b-input-group>
                      </div>
                      <div class="ml-1 mr-1 mt-1 mb-1"
                      style="padding: 2px 5px 2px 5px;">
                      <span>If you don't have Pre LASIK/PRK data you
                        can choose one of the following options</span>
                      </div>
                      <div class="ml-1 mr-1 mt-1 mb-1"
                        style="padding: 2px 5px 2px 5px;">
                      <span>Posterior corneal data (NO IOLMaster 700)</span>
                      <b-input-group class="mt-2 mb-2"
                          prepend="Posterior R1" append="mm">
                        <b-form-input id="input-right-PosteriorR1" type="number"
                        v-model.number="input_right_PosteriorR1" step="any" min=0
                        max=500 size="md"></b-form-input>
                      </b-input-group>
                      <b-input-group class="mt-2 mb-2"
                          prepend="Posterior R2" append="mm">
                        <b-form-input id="input-right-PosteriorR2" type="number"
                        v-model.number="input_right_PosteriorR2" step="any" min=0
                        max=500 size="md"></b-form-input>
                      </b-input-group>
                    </div>
                      <div class="ml-1 mr-1 mt-1 mb-1"
                        style="padding: 2px 5px 2px 5px;">
                      <span>IOLMaster 700 Posterior Keratometry</span>
                      <b-input-group class="mt-2 mb-2"
                          prepend="PK1" append="D">
                        <b-form-input id="input-right-IOLMaster-PK1" type="number"
                        v-model.number="input_right_IOLMaster_PK1" step="any" min=-500
                        max=500 size="md"></b-form-input>
                      </b-input-group>
                      <b-input-group class="mt-2 mb-2"
                          prepend="PK2" append="D">
                        <b-form-input id="input-right-IOLMaster-PK2" type="number"
                        v-model.number="input_right_IOLMaster_PK2" step="any" min=-500
                        max=500 size="md"></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div  class="mt-2" style="color: black; background-color: aliceblue;
                    padding: 2px 5px 8px 5px;">
                    <span v-if="option_calculation_right_postlasik"
                      style="display: flex; justify-content: center;
                        font-family: serif;">REQUIRED</span>
                    <b-input-group class="mt-2 mb-2" id="input-right-AL-group">
                        <b-input-group-prepend><b-input-group-text >AL</b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input id="input-right-AL" type="number"
                          v-model.number="input_right_AL" step="any" min=0
                          :state="checkAL(this.input_right_AL)"
                          max=100 size="md"></b-form-input>
                        <b-input-group-append><b-input-group-text >mm</b-input-group-text>
                        </b-input-group-append>
                        <label class="sr-only" for="input-right-AL-group"></label>
                      <!--<b-form-invalid-feedback style="z-index: 200;" id="input-right-AL-group">
                          For AL > {{this.limitALMax}}mm the Hoffer QST is still under investigation
                        </b-form-invalid-feedback>-->
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="ACD Epith to Lens" append="mm">
                      <b-form-input id="input-right-ACD" type="number"
                        v-model.number="input_right_ACD"
                        step="any" min=0 max=500 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2"  v-if="input_right_AL>=25 & input_right_AL<28
                        & (option_calculation_right_postlasik==false)"
                        prepend="LT" append="mm">
                      <b-form-input id="input-right-LT" type="number"
                      v-model.number="input_right_LT" step="any" min=0
                      max=500 size="md"></b-form-input>
                    </b-input-group>
                      <b-input-group class="mt-2 mb-2"
                        v-if="input_calculation_righteye==options_calculation_choice[0]"
                        prepend="K1" append="D">
                        <b-form-input id="input-right-K1" type="number"
                        v-model.number="input_right_K1" step="any" min=0
                        max=500 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2"
                        v-if="input_calculation_righteye==options_calculation_choice[0]"
                        prepend="K2" append="D">
                        <b-form-input id="input-right-K2" type="number"
                          v-model.number="input_right_K2" step="any" min=0
                          max=500 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2"
                        v-if="input_calculation_righteye==options_calculation_choice[1]"
                        prepend="K Flat" append="D/°">
                        <b-form-input id="input-right-K1" type="number"
                        v-model.number="input_right_K1" step="any" min=0
                        max=500 size="md"></b-form-input>
                      <b-form-input id="input-right-K1Axis" type="number"
                        v-model.number="input_right_K1Axis" step="any" min=0
                        max=180 size="md" v-on:input="setRightK2Axis()"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2"
                        v-if="input_calculation_righteye==options_calculation_choice[1]"
                        id="input-right-K2-group">
                        <b-input-group-prepend><b-input-group-text >K Steep</b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input id="input-right-K2" type="number"
                          v-model.number="input_right_K2" step="any" min=0
                          :state="checkKFlatKSteep(this.input_right_K1, this.input_right_K2)"
                          max=500 size="md"></b-form-input>
                        <b-form-input id="input-right-K2Axis" type="number"
                          v-model.number="input_right_K2Axis" step="any" min=0
                          max=180 size="md" readonly></b-form-input>
                        <b-input-group-append><b-input-group-text >D/°</b-input-group-text>
                        </b-input-group-append>
                        <label class="sr-only" for="input-right-K2-group"></label>
                        <b-form-invalid-feedback style="z-index: 200" id="input-right-K2-group">
                          KSteep &#8805; KFlat
                        </b-form-invalid-feedback>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="IOL">
                      <b-form-select id="input-right-IOLModel" v-model="input_right_IOLModel"
                        v-on:change="setIOLRightModelOptions()"
                        type="text" size="md" :options="getIOLRightModelOptions()">
                        <template #first>
                          <b-form-select-option :value="null" >Manufacturer
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-select id="input-right-IOLType" v-model="input_right_IOLType"
                        v-on:change="setRightpACD()" type="text" size="md"
                        :options="getIOLRightTypeOptions()">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Select IOL
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="Hoffer pACD">
                        <b-form-input id="input-right-pACD" type="number"
                          v-model.number="input_right_pACD"
                          step="any" min=0 max=500 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="SIA" append="D"
                        v-if="input_calculation_righteye==options_calculation_choice[1]">
                        <b-form-input id="input-right-Sica" type="number"
                          v-model.number="input_right_Sica"
                          step="any" min=0 max=360 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="Inc. Meridian" append="°"
                        v-if="input_calculation_righteye==options_calculation_choice[1]">
                        <b-form-input id="input-right-Meridian" type="number"
                          v-model.number="input_right_Meridian"
                          step="any" min=0 max=360 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2" prepend="Target PO Rx" append="D">
                        <b-form-input id="input-right-TargetRx" type="number"
                        v-model.number="input_right_TargetRx"
                        min=-500 max=500 step="0.25" size="md"></b-form-input>
                    </b-input-group>
                  </div>
                </div>
              </b-card>
              <!-- Left Eye -->
              <b-card body-bg-variant="default">
                <div style="display: flex; flex-direction: row; justify-content: space-around;">
                  <div>
                    <h2 class="text-center">OS Left</h2>
                  </div>
                <!--LeftEye SE/Toric Calculation -->
                <div class="mt-1" v-if="option_calculation_left_postlasik==false">
                  <b-form-radio-group  id="input-caculation-lefteye" size="sm"
                    class="justify-content-center"
                    v-model="input_calculation_lefteye"
                    buttons name="input-radio-caculation-lefteye"
                    :options="options_calculation_choice" required
                    v-on:change="leftCalculationChoiceChanged()"
                    button-variant="outline-primary">
                  </b-form-radio-group>
                </div>
                <div class="mt-1">
                    <b-form-checkbox size="sm"
                      v-model="option_calculation_left_postlasik"
                      v-on:change="leftCalculationChoicePostLasikChanged()"
                      button button-variant="outline-primary">
                      Post myopic LASIK/PRK
                    </b-form-checkbox>
                </div>
              </div>
                <!--Line 1-->
                <div class="mt-1 mb-1">
                  <div  style="color: black;" class="mb-2"
                    v-if="option_calculation_left_postlasik==true">
                  </div>
                  <div v-if="option_calculation_left_postlasik==true"
                    style="color: black; background-color: #d0e1f9;
                    padding: 2px 5px 2px 5px;">
                    <span style="display: flex; justify-content: center;
                        font-family: serif;">OPTIONAL</span>
                      <div class="ml-1 mr-1 mb-1"
                      style="padding: 2px 5px 2px 5px;">
                      <span>Pre LASIK/PRK data</span>
                      <b-input-group class="mt-2 mb-2"
                          prepend="K1pre" append="D">
                          <b-form-input id="input-left-K1pre" type="number"
                          v-model.number="input_left_K1pre" step="any" min=0
                          max=500 size="md"></b-form-input>
                      </b-input-group>
                      <b-input-group class="mt-2 mb-2"
                            prepend="K2pre" append="D">
                          <b-form-input id="input-left-K2pre" type="number"
                          v-model.number="input_left_K2pre" step="any" min=0
                          max=500 size="md"></b-form-input>
                      </b-input-group>
                      </div>
                      <div class="ml-1 mr-1 mt-1 mb-1"
                      style="padding: 2px 5px 2px 5px;">
                      <span>If you don't have Pre LASIK/PRK data you
                        can choose one of the following options</span>
                      </div>
                      <div class="ml-1 mr-1 mt-1 mb-1"
                        style="padding: 2px 5px 2px 5px;">
                      <span>Posterior corneal data (NO IOLMaster 700)</span>
                      <b-input-group class="mt-2 mb-2"
                          prepend="Posterior R1" append="mm">
                        <b-form-input id="input-left-PosteriorR1" type="number"
                        v-model.number="input_left_PosteriorR1" step="any" min=0
                        max=500 size="md"></b-form-input>
                      </b-input-group>
                       <b-input-group class="mt-2 mb-2"
                          prepend="Posterior R2" append="mm">
                        <b-form-input id="input-left-PosteriorR2" type="number"
                        v-model.number="input_left_PosteriorR2" step="any" min=0
                        max=500 size="md"></b-form-input>
                      </b-input-group>
                    </div>
                      <div class="ml-1 mr-1 mt-1 mb-1"
                        style="padding: 2px 5px 2px 5px;">
                      <span>IOLMaster 700 Posterior Keratometry</span>
                      <b-input-group class="mt-2 mb-2"
                          prepend="PK1" append="D">
                        <b-form-input id="input-left-IOLMaster-PK1" type="number"
                        v-model.number="input_left_IOLMaster_PK1" step="any" min=-500
                        max=500 size="md"></b-form-input>
                      </b-input-group>
                      <b-input-group class="mt-2 mb-2"
                          prepend="PK2" append="D">
                        <b-form-input id="input-left-IOLMaster-PK2" type="number"
                        v-model.number="input_left_IOLMaster_PK2" step="any" min=-500
                        max=500 size="md"></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                  <div  class="mt-2" style="color: black; background-color: aliceblue;
                    padding: 2px 5px 8px 5px;">
                    <span v-if="option_calculation_left_postlasik"
                      style="display: flex; justify-content: center;
                        font-family: serif;">REQUIRED</span>
                    <b-input-group class="mt-2 mb-2" id="input-left-AL-group">
                        <b-input-group-prepend><b-input-group-text >AL</b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input id="input-left-AL" type="number"
                          v-model.number="input_left_AL" step="any" min=0
                          :state="checkAL(this.input_left_AL)"
                          max=100 size="md"></b-form-input>
                        <b-input-group-append><b-input-group-text >mm</b-input-group-text>
                        </b-input-group-append>
                        <label class="sr-only" for="input-left-AL-group"></label>
                        <!--<b-form-invalid-feedback style="z-index: 200" id="input-left-AL-group">
                          For AL > {{this.limitALMax}}mm the Hoffer QST is still under investigation
                        </b-form-invalid-feedback>-->
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="ACD Epith to Lens" append="mm">
                      <b-form-input ref="input-left-ACD" type="number"
                        v-model.number="input_left_ACD"
                        step="any" min=0 max=500 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2"  v-if="input_left_AL>=25 & input_left_AL<28
                        & (option_calculation_left_postlasik==false)"
                        prepend="LT" append="mm">
                      <b-form-input id="input-left-LT" type="number"
                      v-model.number="input_left_LT" step="any" min=0
                      max=500 size="md"></b-form-input>
                    </b-input-group>
                      <b-input-group class="mt-2 mb-2"
                        v-if="input_calculation_lefteye==options_calculation_choice[0]"
                        prepend="K1" append="D">
                        <b-form-input id="input-left-K1" type="number"
                        v-model.number="input_left_K1" step="any" min=0
                        max=500 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2"
                        v-if="input_calculation_lefteye==options_calculation_choice[0]"
                        prepend="K2" append="D">
                        <b-form-input id="input-left-K2" type="number"
                          v-model.number="input_left_K2" step="any" min=0
                          max=500 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2"
                        v-if="input_calculation_lefteye==options_calculation_choice[1]"
                        prepend="K Flat" append="D/°">
                        <b-form-input id="input-left-K1" type="number"
                        v-model.number="input_left_K1" step="any" min=0
                        max=500 size="md"></b-form-input>
                      <b-form-input id="input-left-K1Axis" type="number"
                        v-model.number="input_left_K1Axis" step="any" min=0
                        max=180 size="md" v-on:input="setLeftK2Axis()"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2"
                        v-if="input_calculation_lefteye==options_calculation_choice[1]"
                        id="input-left-K2-group">
                        <b-input-group-prepend><b-input-group-text >K Steep</b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input id="input-left-K2" type="number"
                          v-model.number="input_left_K2" step="any" min=0
                          :state="checkKFlatKSteep(this.input_left_K1, this.input_left_K2)"
                          max=500 size="md"></b-form-input>
                        <b-form-input id="input-left-K2Axis" type="number"
                          v-model.number="input_left_K2Axis" step="any" min=0
                          max=180 size="md" readonly></b-form-input>
                        <b-input-group-append><b-input-group-text >D/°</b-input-group-text>
                        </b-input-group-append>
                        <label class="sr-only" for="input-left-K2-group"></label>
                        <b-form-invalid-feedback style="z-index: 200" id="input-left-K2-group">
                          KSteep &#8805; KFlat
                        </b-form-invalid-feedback>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="IOL">
                      <b-form-select id="input-left-IOLModel" v-model="input_left_IOLModel"
                        v-on:change="setIOLLeftModelOptions()"
                        type="text" size="md" :options="getIOLLeftModelOptions()">
                        <template #first>
                          <b-form-select-option :value="null" >Manufacturer
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-select id="input-left-IOLType" v-model="input_left_IOLType"
                        v-on:change="setLeftpACD()" type="text" size="md"
                        :options="getIOLLeftTypeOptions()">
                        <template #first>
                          <b-form-select-option :value="null" disabled>Select IOL
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="Hoffer pACD">
                        <b-form-input id="input-left-pACD" type="number"
                          v-model.number="input_left_pACD"
                          step="any" min=0 max=500 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="SIA" append="D"
                        v-if="input_calculation_lefteye==options_calculation_choice[1]">
                        <b-form-input id="input-left-Sica" type="number"
                          v-model.number="input_left_Sica"
                          step="any" min=0 max=360 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2 mb-2" prepend="Inc. Meridian" append="°"
                        v-if="input_calculation_lefteye==options_calculation_choice[1]">
                        <b-form-input id="input-left-Meridian" type="number"
                          v-model.number="input_left_Meridian"
                          step="any" min=0 max=360 size="md"></b-form-input>
                    </b-input-group>
                    <b-input-group class="mt-2" prepend="Target PO Rx"  append="D">
                        <b-form-input id="input-left-TargetRx" type="number"
                        v-model.number="input_left_TargetRx"
                        min=-500 max=500 step="0.25" size="md"></b-form-input>
                    </b-input-group>
                  </div>
                </div>
              </b-card>
          </b-card-group>
          <!--Calculate -->
          <b-form-row align="center" class="mt-2">
            <b-col id="calculate">
              <b-button class="mb-2" type="submit" variant="success">Calculate</b-button>
              <b-button class="mb-2 ml-1 " type="reset" variant="secondary">Reset</b-button>
              <b-button v-if="(resultsRightAvailable==true | resultsLeftAvailable==true)"
                class="mb-2 ml-1 " variant="secondary" @click="createPDF">Print</b-button>
              <b-button class="mb-2 ml-1" variant="secondary" id="show-btn"
                @click="$bvModal.show('bv-modal-custom')">Disclaimer</b-button>
              <b-modal id="bv-modal-custom" content-class="modal-custom" hide-header-close
                hide-footer no-close-on-backdrop no-close-on-esc data-backdrop="static">
                <template #modal-title>
                  <h2 style="margin: 0px 0px 0px 0px">Disclaimer</h2>
                </template>
                <div class="d-block text-center">
                  <h3 class="mt-2" style="text-align: justify">
                    This Website is providing the Service of calculating the IOL power
                    using the Hoffer® QST Formula and the Naeser/Savini Toric Calculator.
                  </h3>
                  <h3 class="mt-3" style="text-align: justify">
                    We, the authors of these formulas and this website assume no
                    responsibility for the clinical outcomes of any given patient.
                    The choice of IOL power for any patient is the sole responsibility
                    of the individual surgeon's clinical judgment.
                  </h3>
                </div>
                <b-button class="mt-4 mb-2" block @click="$bvModal.hide('bv-modal-custom')"
                  variant="info"> Agree</b-button>
              </b-modal>
            </b-col>
          </b-form-row>
          <!-- Output Right Eye , Left Eye -->
          <b-card-group deck v-if="(resultsRightAvailable==true | resultsLeftAvailable==true)">
            <!--<b-card class="mt-3" header="Input Values" bg-variant="dark"
              text-variant="white" border-variant="primary">
              <pre class="m-0" style="color: white">{{ this.getFormData() }}</pre>
              <b-table
                :items="this.getInputTableData()"
                striped bordered borderless dark small fixed thead-class="d-none" responsive>
              </b-table>
            </b-card>-->
            <b-card class="mb-3" bg-variant="default">
              <!--<pre class="m-0" style="color: white;  overflow-y: auto;
              max-height: 250px;">{{ this.output_data }}</pre>-->
              <div class="tables">
                <div class="column-1">
                  <b-table id="right-se-table" v-if="resultsRightAvailable==true"
                    :items="this.OutputRightSETableData"
                    :fields="[{ key: 'IOL', label: 'IOL power (D)', class: 'text-center',
                      thStyle: 'font-family: sans-serif' },
                    { key: 'Rx', label: 'Predicted Rx', sortable: false, class: 'text-center',
                    thStyle: 'font-family: sans-serif' }]"
                    bordered small fixed responsive>
                  </b-table>
                  <b-table id="right-toric-ppra-table" v-if="(resultsRightAvailable==true
                      && input_calculation_righteye==options_calculation_choice[1])"
                    :items="this.OutputRightTORICPPRATableData"
                    :fields="[{ key: 'IOL_Name', label: 'IOL',class: 'text-center',
                      thStyle: 'font-family: sans-serif' },
                    { key: 'IOL_Cylinder', label: 'Predicted Cyl (D)', class: 'text-center',
                    thStyle: 'font-family: sans-serif' },
                    { key: 'IOL_Axis', label: 'Axis °', class: 'text-center',
                    thStyle: 'font-family: sans-serif' }]"
                    bordered fixed small responsive>
                  </b-table>
                </div>
                <div class="column-2">
                    <div class="iols-svg" v-if="(resultsRightAvailable==true
                      && input_calculation_righteye==options_calculation_choice[1])">
                    <object ref="iol-right-svg" id="iol-right-svg" style="width: 90%;"
                      v-bind:data="iolRightSvg" @load="updateRightIOLSvg"
                      type="image/svg+xml">
                    </object>
                  </div>
                  <b-table id="right-toric-astigmatism-table" v-if="(resultsRightAvailable==true
                      && input_calculation_righteye==options_calculation_choice[1])"
                    :items="this.OutputRightTORICAstTableData"
                    :fields="[{ key: 'Keys', label: 'Optimized Astigmatism',class: 'text-center',
                      thStyle: 'font-family: sans-serif' },
                    { key: 'Diopters', label: 'D', class: 'text-center',
                    thStyle: 'font-family: sans-serif' },
                    { key: 'Axis', label: 'Axis °', class: 'text-center',
                    thStyle: 'font-family: sans-serif' }]"
                    bordered fixed small responsive>
                  </b-table>
                </div>
              </div>
            </b-card>
            <b-card class="mb-3" bg-variant="default">
              <!--<pre class="m-0" style="color: white;  overflow-y: auto;
              max-height: 250px;">{{ this.output_data }}</pre>-->
              <div class="tables">
                <div class="column-1">
                  <b-table id="left-se-table" v-if="resultsLeftAvailable==true"
                    :items="this.OutputLeftSETableData"
                    :fields="[{ key: 'IOL', label: 'IOL power (D)', class: 'text-center',
                      thStyle: 'font-family: sans-serif' },
                    { key: 'Rx', label: 'Predicted Rx', sortable: false, class: 'text-center',
                    thStyle: 'font-family: sans-serif' }]"
                    bordered small fixed responsive>
                  </b-table>
                  <b-table id="left-toric-ppra-table" v-if="(resultsLeftAvailable==true
                    && input_calculation_lefteye==options_calculation_choice[1])"
                    :items="this.OutputLeftTORICPPRATableData"
                    :fields="[{ key: 'IOL_Name', label: 'IOL',class: 'text-center',
                      thStyle: 'font-family: sans-serif' },
                    { key: 'IOL_Cylinder', label: 'Predicted Cyl (D)', class: 'text-center',
                    thStyle: 'font-family: sans-serif' },
                    { key: 'IOL_Axis', label: 'Axis °', class: 'text-center',
                    thStyle: 'font-family: sans-serif' }]"
                    bordered fixed small responsive>
                  </b-table>
                </div>
                <div class="column-2">
                  <div class="iols-svg" v-if="(resultsLeftAvailable==true
                      && input_calculation_lefteye==options_calculation_choice[1])">
                    <object ref="iol-left-svg" id="iol-left-svg"
                      style="width: 90%;"
                      v-bind:data="iolLeftSvg" @load="updateLeftIOLSvg"
                      type="image/svg+xml">
                    </object>
                  </div>
                  <b-table id="left-toric-astigmatism-table" v-if="(resultsLeftAvailable==true
                    & input_calculation_lefteye==options_calculation_choice[1])"
                    :items="this.OutputLeftTORICAstTableData"
                    :fields="[{ key: 'Keys', label: 'Optimized Astigmatism',class: 'text-center',
                      thStyle: 'font-family: sans-serif' },
                    { key: 'Diopters', label: 'D', class: 'text-center',
                    thStyle: 'font-family: sans-serif' },
                    { key: 'Axis', label: 'Axis °', class: 'text-center',
                    thStyle: 'font-family: sans-serif' }]"
                    bordered fixed small responsive>
                  </b-table>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import iolLeftSvg from '@/assets/iolLeft.svg';
import iolRightSvg from '@/assets/iolRight.svg';

export default {
  data() {
    return {
      // Limits
      limitALMax: 100,
      // Svg
      iolLeftSvg,
      iolRightSvg,
      iolRightPng: null,
      iolLeftPng: null,
      resultsRightAvailable: false,
      resultsLeftAvailable: false,
      // General
      input_Surgeon: null,
      input_PatientName: null,
      options_Gender: ['Male', 'Female'],
      input_Gender: null,
      options_index: [
        { value: 1.3375, text: '1.3375' },
        { value: 1.332, text: '1.332' },
        { value: 1.3315, text: '1.3315' },
      ],
      input_index: 1.3375,
      options_SE_IOLS: {
        Alcon: {
          'Alcon - SN60WF/SA60WF': 5.67,
          'Alcon - SN60AT/SA60AT': 5.43,
          'Alcon - CLAREON CNA0T0': 5.76,
          'Alcon - CLAREON SY60WF': 5.8,
          'Alcon - CLAREON PANOPTIX SY60WF': 5.61,
          'Alcon - CLAREON VIVITY CNAET0': 5.67,
          'Alcon - MA60AC': 5.67,
          'Alcon - MA60MA': 5.8,
          'Alcon - VIVITY DFT015': 5.64,
          'Alcon - PANOPTIX TFNT00': 5.77,
        },
        'B+L': {
          'B+L - EnVista MX60': 5.68,
          'B+L - EyeCEE One': 6,
          'B+L - Akreos MICS MI60': 5.61,
          'B+L - Akreos Adapt AO': 5.18,
          'B+L - LuxSmart': 5.23,
          'B+L - LuxGood': 5.67,
        },
        Hoya: {
          'Hoya - Vivinex XY1/XC1': 5.721,
          'Hoya - Nanex NY1-SP/NC1-SP': 5.715,
          'Hoya - iSert AF-1 150/151/250/251/254/255': 5.30,
          'Hoya - iSert AF-1 PC-60AD/PY60AD': 5.30,
          'Hoya - iSert AF-1 PC-60R': 5.24,
          'Hoya - AF-1 YA-60BB': 5.50,
          'Hoya - AF-1 VA-60BB': 5.32,
          'Hoya - Gemetric/Gemetric Plus XY1-G(P)T': 5.64,
          'Hoya - Impress XY1-EM': 5.721,
        },
        'J&J': {
          'J&J - SENSAR AAB00': 5.56,
          'J&J - SENSAR AR40e': 5.39,
          'J&J - SENSAR AR40E': 5.41,
          'J&J - SENSAR AR40M': 5.41,
          'J&J - TECNIS ZCB00/ZCB00V': 5.8,
          'J&J - TECNIS ZA9003': 5.61,
          'J&J - TECNIS TORIC ZCT': 5.7,
          'J&J - SYNERGY ZFR00V': 5.7,
          'J&J - SYNERGY ZFW': 5.7,
          'J&J - TECNIS ZKB00/ZLB00/ZMB00': 5.7,
          'J&J - TECNIS ZKU/ZLU': 5.72,
          'J&J - SYMPHONY ZXR00/ZXR00V': 5.59,
          'J&J - SYMPHONY ZXT/ZXW': 5.7,
          'J&J - EYHANCE ICB00/DIB00': 5.8,
        },
        Kowa: {
          'Kowa - Avansee Preload 1-piece CP2.2R/YP2.2R': 5.69,
          'Kowa - Avansee Preload 3-piece PN6AS/PU6AS': 5.64,
        },
        'Md-Tech': {
          'Md-Tech I-see ISP60H': 5.39,
          'Md-Tech I-see Carlevale ISP60VL': 5.20,
        },
        Medicontur: {
          'Medicontur - Trifocal Liberty 677 PMY/MY': 5.431,
          'Medicontur - Bi-Flex 677P(Y)/677AD(Y)': 5.431,
          'Medicontur - Q-Flex 640P(Y)/640AD(Y)': 5.46,
          'Medicontur - Elon 877PEY': 5.46,
        },
        PhysIOL: {
          'PhysIOL - Micropure 123': 5.85,
          'PhysIOL - Isopure 123': 5.85,
          'PhysIOL - PodEye': 5.85,
          'PhysIOL - FineVision HP': 5.85,
        },
        Rayner: {
          'Rayner - RayOne Spheric RAO100C': 5.32,
          'Rayner - RayOne Aspheric RAO600C': 5.32,
          'Rayner - RayOne Hydrophobic RAO800C': 5.58,
          'Rayner - RayOne EMV RAO200E': 5.32,
          'Rayner - RayOne Trifocal RAO603F': 5.32,
        },
        SIFI: {
          'SIFI - Evolux': 5.11,
          'SIFI - MiniWELL': 5.32,
          'SIFI - MiniWELL Proxa': 5.21,
          'SIFI - Mini 2': 5.354,
          'SIFI - Mini 4': 5.63,
        },
        Soleko: {
          'Soleko - FIL 611': 5.6,
          'Soleko - Evolve': 5.33,
          'Soleko - HFO 610 (hydrophobic)': 6.1,
          'Soleko - Optofobic 2.0 (hydrophobic 2nd gen.)': 5.99,
          'Soleko - FIL SSF (sutureless scleral fixation)': 5.48,
        },
        Zeiss: {
          'Zeiss - CT SPHERIS 209 M': 5.16,
          'Zeiss - CT LUCIA 211P/PY': 6.308,
          'Zeiss - CT LUCIA 221P': 6.29,
          'Zeiss - CT LUCIA 611P/PY': 6.27,
          'Zeiss - CT LUCIA 621P/PY': 6.073,
          'Zeiss - CT ASPHINA 409M/MP': 5.179,
          'Zeiss - CT ASPHINA 509M/MP': 4.911,
          'Zeiss - AT LARA 829MP': 5.02,
          'Zeiss - AT LISA TRI 839MP': 5.347,
        },
      },
      options_TORIC_IOLS: {
        Alcon: {
          'Alcon - SN6ATx (2-9)': 5.90,
          'Alcon - VIVITY DFTx15 (2-6)': 5.64,
          'Alcon - PANOPTIX TFNTx (30-60)': 5.83,
          'Alcon - CLAREON VIVITY TORIC CNAET2-6': 5.67,
          'Alcon - CLAREON PANOPTIX CNWTT2-6': 5.61,
        },
        'B+L': {
          'B+L - LuxSmart Toric': 5.23,
          'B+L - LuxGood Toric': 5.67,
          'B+L - EnVista Toric MX60T': 5.61,
          'B+L - EnVista Toric MX60T Enhanced Preloaded': 5.61,
        },
        Hoya: {
          'Hoya - Vivinex XY1ATx-SP': 5.721,
          'Hoya - Gemetric/Gemetric Plus Toric': 5.64,
        },
        'J&J': {
          'J&J - TECNIS TORIC ZCT': 5.7,
          'J&J - EYHANCE TORIC DIU': 5.7,
          'J&J - SYMFONY TORIC ZXT': 5.7,
          'J&J - SYNERGY TORIC DFW': 5.7,
        },
        Kowa: {
          'Kowa - Avansee Preload1P CP-Tx': 5.602,
        },
        Medicontur: {
          'Medicontur - Toric Bi-Flex 677A(Y)': 5.431,
          'Medicontur - Toric Trifocal Liberty 677MTY': 5.431,
        },
        PhysIOL: {
          'PhysIOL - PodEye toric': 5.85,
          'PhysIOL - FineVision toric': 5.59,
        },
        Rayner: {
          'Rayner - RayOne RAO610T': 5.32,
          'Rayner - Rayone Trifocal Toric RAO613Z': 5.32,
          'Rayner - Rayone EMV Toric': 5.32,
        },
        SIFI: {
          'SIFI - MiniWELL Toric': 5.426,
          'SIFI - Mini Toric': 5.38,
        },
        Zeiss: {
          'Zeiss - AT LARA TORIC 929M/MP': 5.07,
        },
      },
      // Right
      input_right_AL: null,
      input_right_ACD: null,
      input_right_K1: null,
      input_right_K2: null,
      input_right_R1: null,
      input_right_R2: null,
      input_right_pACD: null,
      input_right_TargetRx: 0,
      input_right_IOLType: null,
      input_right_IOLModel: null,
      option_calculation_right_postlasik: false,
      input_right_K1pre: null,
      input_right_K2pre: null,
      input_right_IOLMaster_PK1: null,
      input_right_IOLMaster_PK2: null,
      input_right_PosteriorR1: null,
      input_right_PosteriorR2: null,
      input_right_LT: null,
      // Left
      input_left_AL: null,
      input_left_ACD: null,
      input_left_K1: null,
      input_left_K2: null,
      input_left_R1: null,
      input_left_R2: null,
      input_left_pACD: null,
      input_left_TargetRx: 0,
      input_left_IOLType: null,
      input_left_IOLModel: null,
      option_calculation_left_postlasik: false,
      input_left_K1pre: null,
      input_left_K2pre: null,
      input_left_IOLMaster_PK1: null,
      input_left_IOLMaster_PK2: null,
      input_left_PosteriorR1: null,
      input_left_PosteriorR2: null,
      input_left_LT: null,
      // Result
      output_left: null,
      output_right: null,
      show: true,
      // ---- Toric
      options_calculation_choice: ['SE', 'Toric'],
      input_calculation_righteye: 'SE',
      input_calculation_lefteye: 'SE',
      input_left_Meridian: null,
      input_right_Meridian: null,
      input_left_Sica: null,
      input_right_Sica: null,
      input_left_K2Axis: null,
      input_right_K2Axis: null,
      input_left_K1Axis: null,
      input_right_K1Axis: null,
      // Outputs Tables
      OutputRightSETableData: null,
      OutputRightTORICPPRATableData: null,
      OutputRightTORICAstTableData: null,
      OutputLeftSETableData: null,
      OutputLeftTORICPPRATableData: null,
      OutputLeftTORICAstTableData: null,
      // Url
      // url: 'http://localhost:8000',
      url: '',
      currentYear: new Date().getFullYear(),
    };
  },
  mounted() {
    // Init page function
    // https://stackoverflow.com/questions/40714319/how-to-call-a-vue-js-function-on-page-load
    // https://michaelnthiessen.com/call-method-on-page-load/
    this.$bvModal.show('bv-modal-custom');
  },
  methods: {
    getDisclaimerText() {
      const text = `
  This Website is providing the Service of calculating the IOL power using the Hoffer® QST Formula and the
  Naeser/Savini Toric Calculator. We, the authors of these formulas and this website assume no responsibility
  for the clinical outcomes of any given patient. The choice of IOL power for any patient is
  the sole responsibility of the individual surgeon's clinical judgment.`;
      return text;
    },
    buttonResetClick() {
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    createPDF() {
      // eslint-disable-next-line
      const pdf = new jsPDF (
        {
          orintation: 'portrait',
          unit: 'mm',
          format: 'a4', // 210 mm. 297
          putOnlyUsedFonts: true,
          floatPrecision: 16,
        },
      );
      // 230 -40 = 190 / 3 --> 63
      pdf.setFontSize(22);
      pdf.text(10, 20, 'Hoffer');
      pdf.text(36.5, 20, 'QST - Naeser/Savini Calculations');
      pdf.setFontSize(12);
      pdf.text(31.5, 17.5, '®');
      pdf.setFontSize(12);
      pdf.line(10, 25, 200, 25);
      if (this.input_Surgeon === null || this.input_Surgeon === '') {
        pdf.text(10, 35, `Surgeon: Unknown, Patient: ${this.input_PatientName},  Sex: ${this.input_Gender},  Index: ${this.input_index}`);
      } else {
        pdf.text(10, 35, `Surgeon: ${this.input_Surgeon}, Patient: ${this.input_PatientName},  Sex: ${this.input_Gender},  Index: ${this.input_index}`);
      }
      pdf.line(10, 42, 200, 42);
      pdf.line(105, 42, 105, 262);
      pdf.line(10, 262, 200, 262);
      if (this.resultsRightAvailable) {
        pdf.setFontSize(15);
        pdf.text(10, 52, 'OD Right Eye');
        pdf.setFontSize(10);
        let iolTextRight = this.input_right_IOLType;
        if (iolTextRight === null) {
          iolTextRight = 'Not Selected';
        }
        if (this.option_calculation_right_postlasik) {
          pdf.text(53, 60, 'Post Myopic LASIK/PKR');
          if (this.input_right_K1pre === null || this.input_right_K1pre === '') {
            pdf.text(53, 65, 'K1pre:');
          } else {
            pdf.text(53, 65, `K1pre: ${this.input_right_K1pre} D`);
          }
          if (this.input_right_K2pre === null || this.input_right_K2pre === '') {
            pdf.text(53, 70, 'K2pre:');
          } else {
            pdf.text(53, 70, `K2pre: ${this.input_right_K2pre} D`);
          }
          if (this.input_right_IOLMaster_PK1 === null || this.input_right_IOLMaster_PK1 === '') {
            pdf.text(53, 75, 'IOLMaster 700 PK1:');
          } else {
            pdf.text(53, 75, `IOLMaster 700 PK1: ${this.input_right_IOLMaster_PK1} D`);
          }
          if (this.input_right_IOLMaster_PK2 === null || this.input_right_IOLMaster_PK2 === '') {
            pdf.text(53, 80, 'IOLMaster 700 PK2:');
          } else {
            pdf.text(53, 80, `IOLMaster 700 PK2: ${this.input_right_IOLMaster_PK2} D`);
          }
          if (this.input_right_PosteriorR1 === null || this.input_right_PosteriorR1 === '') {
            pdf.text(53, 85, 'Posterior R1:');
          } else {
            pdf.text(53, 85, `Posterior R1: ${this.input_right_PosteriorR1} mm`);
          }
          if (this.input_right_PosteriorR2 === null || this.input_right_PosteriorR2 === '') {
            pdf.text(53, 90, 'Posterior R2:');
          } else {
            pdf.text(53, 90, `Posterior R2: ${this.input_right_PosteriorR2} mm`);
          }
        }
        if (this.input_calculation_righteye === this.options_calculation_choice[0]) { // TORIC
          pdf.text(10, 60, `Calculation: ${this.input_calculation_righteye}`);
          pdf.text(10, 65, `AL: ${this.input_right_AL} mm`);
          pdf.text(10, 70, `K1: ${this.input_right_K1} D`);
          pdf.text(10, 75, `K2: ${this.input_right_K2} D`);
          pdf.text(10, 80, `Hoffer pACD: ${this.input_right_pACD}`);
          pdf.text(10, 85, `Target PO Rx: ${this.input_right_TargetRx}`);
          pdf.text(10, 90, `ACD Epith to Lens: ${this.input_right_ACD} mm`);
          pdf.text(10, 95, `IOL: ${iolTextRight}`);
        } else {
          pdf.text(10, 60, `Calculation: ${this.input_calculation_righteye}`);
          pdf.text(10, 65, `AL: ${this.input_right_AL} mm`);
          pdf.text(10, 70, `K Flat: ${this.input_right_K1} D`);
          pdf.text(10, 75, `K Flat Axis: ${this.input_right_K1Axis} °`);
          pdf.text(10, 80, `K Steep: ${this.input_right_K2} D`);
          pdf.text(10, 85, `K Steep Axis: ${this.input_right_K2Axis} °`);
          pdf.text(10, 90, `Hoffer pACD: ${this.input_right_pACD}`);
          pdf.text(10, 95, `SIA: ${this.input_right_Sica} D`);
          pdf.text(10, 100, `Inc. Meridian: ${this.input_right_Meridian} °`);
          pdf.text(10, 105, `Target PO Rx: ${this.input_right_TargetRx}`);
          pdf.text(10, 110, `ACD Epith to Lens: ${this.input_right_ACD} mm`);
          pdf.text(10, 115, `IOL: ${iolTextRight}`);
          const bestRightTORICPPRAIndex = this.rightTORICPPRAIndex;
          pdf.autoTable({
            html: '#right-toric-ppra-table',
            theme: 'plain',
            columnStyles: {
              0: { cellWidth: 16 },
              1: { cellWidth: 16 },
              2: { cellWidth: 12 },
            },
            styles: {
              fontSize: 8,
              lineWidth: 0.5,
              lineColor: 1,
            },
            tableWidth: 44,
            startY: 175,
            margin: {
              top: 175,
              left: 10,
            },
            /* headStyles: {
              fillColor: [0, 0, 0],
            }, */
            didParseCell(data) {
              if (data.section === 'body' && data.row.index === bestRightTORICPPRAIndex) {
                // eslint-disable-next-line
                data.cell.styles.fillColor = [66, 186, 150];
              }
            },
          });
          pdf.autoTable({
            html: '#right-toric-astigmatism-table',
            theme: 'plain',
            columnStyles: {
              0: { cellWidth: 21 },
              1: { cellWidth: 12 },
              2: { cellWidth: 12 },
            },
            styles: {
              fontSize: 8,
              lineWidth: 0.5,
              lineColor: 1,
            },
            tableWidth: 45,
            startY: 175,
            margin: {
              top: 175,
              left: 57,
            },
          });
          if (this.iolRightPng != null) {
            pdf.addImage(this.iolRightPng, 'png', 52, 122, 50, 40); //  NaN, 'SLOW');
          }
        }
        const bestRightSEIndex = this.rightSEIndex;
        pdf.autoTable({
          html: '#right-se-table',
          theme: 'plain',
          columnStyles: {
            0: { cellWidth: 20 },
            1: { cellWidth: 20 },
          },
          styles: {
            fontSize: 8,
            lineWidth: 0.5,
            lineColor: 1,
          },
          tableWidth: 40,
          startY: 120,
          margin: {
            top: 120,
            left: 10,
          },
          didParseCell(data) {
            // const rows = data.table.body
            if (data.section === 'body' && data.row.index === bestRightSEIndex) {
              // eslint-disable-next-line
              data.cell.styles.fillColor = [66, 186, 150];
            }
          },
        });
      }
      if (this.resultsLeftAvailable) {
        pdf.setFontSize(15);
        pdf.text(110, 52, 'OS Left Eye');
        pdf.setFontSize(10);
        let iolTextLeft = this.input_left_IOLType;
        if (iolTextLeft === null) {
          iolTextLeft = 'Not Selected';
        }
        if (this.option_calculation_left_postlasik) {
          pdf.text(153, 60, 'Post Myopic LASIK/PKR');
          if (this.input_left_K1pre === null || this.input_left_K1pre === '') {
            pdf.text(153, 65, 'K1pre:');
          } else {
            pdf.text(153, 65, `K1pre: ${this.input_left_K1pre} D`);
          }
          if (this.input_left_K2pre === null || this.input_left_K2pre === '') {
            pdf.text(153, 70, 'K2pre:');
          } else {
            pdf.text(153, 70, `K2pre: ${this.input_left_K2pre} D`);
          }
          if (this.input_left_IOLMaster_PK1 === null || this.input_left_IOLMaster_PK1 === '') {
            pdf.text(153, 75, 'IOLMaster 700 PK1:');
          } else {
            pdf.text(153, 75, `IOLMaster 700 PK1: ${this.input_left_PosteriorR1} D`);
          }
          if (this.input_left_IOLMaster_PK2 === null || this.input_left_IOLMaster_PK2 === '') {
            pdf.text(153, 80, 'IOLMaster 700 PK2:');
          } else {
            pdf.text(153, 80, `IOLMaster 700 PK2: ${this.input_left_PosteriorR2} D`);
          }
          if (this.input_left_PosteriorR2 === null || this.input_left_PosteriorR2 === '') {
            pdf.text(153, 85, 'Posterior R2:');
          } else {
            pdf.text(153, 85, `Posterior R2: ${this.input_left_PosteriorR2} mm`);
          }
          if (this.input_left_PosteriorR1 === null || this.input_left_PosteriorR1 === '') {
            pdf.text(153, 90, 'Posterior R1:');
          } else {
            pdf.text(153, 90, `Posterior R1: ${this.input_left_PosteriorR1} mm`);
          }
          if (this.input_left_PosteriorR2 === null || this.input_left_PosteriorR2 === '') {
            pdf.text(153, 95, 'Posterior R2:');
          } else {
            pdf.text(153, 95, `Posterior R2: ${this.input_left_PosteriorR2} mm`);
          }
        }
        if (this.input_calculation_lefteye === this.options_calculation_choice[0]) { // TORIC
          pdf.text(110, 60, `Calculation: ${this.input_calculation_lefteye}`);
          pdf.text(110, 65, `AL: ${this.input_left_AL} mm`);
          pdf.text(110, 70, `K1: ${this.input_left_K1} D`);
          pdf.text(110, 75, `K2: ${this.input_left_K2} D`);
          pdf.text(110, 80, `Hoffer pACD: ${this.input_left_pACD}`);
          pdf.text(110, 85, `Target PO Rx: ${this.input_left_TargetRx}`);
          pdf.text(110, 90, `ACD Epith to Lens: ${this.input_left_ACD} mm`);
          pdf.text(110, 95, `IOL: ${iolTextLeft}`);
        } else {
          pdf.text(110, 60, `Calculation: ${this.input_calculation_lefteye}`);
          pdf.text(110, 65, `AL: ${this.input_left_AL} mm`);
          pdf.text(110, 70, `K Flat: ${this.input_left_K1} D`);
          pdf.text(110, 75, `K Flat Axis: ${this.input_left_K1Axis} °`);
          pdf.text(110, 80, `K Steep: ${this.input_left_K2} D`);
          pdf.text(110, 85, `K Steep Axis: ${this.input_left_K2Axis} °`);
          pdf.text(110, 90, `Hoffer pACD: ${this.input_left_pACD}`);
          pdf.text(110, 95, `SIA: ${this.input_left_Sica} D`);
          pdf.text(110, 100, `Inc. Meridian: ${this.input_left_Meridian} °`);
          pdf.text(110, 105, `Target PO Rx: ${this.input_left_TargetRx}`);
          pdf.text(110, 110, `ACD Epith to Lens: ${this.input_left_ACD} mm`);
          pdf.text(110, 115, `IOL: ${iolTextLeft}`);

          const bestLeftTORICPPRAIndex = this.leftTORICPPRAIndex;
          pdf.autoTable({
            html: '#left-toric-ppra-table',
            theme: 'plain',
            columnStyles: {
              0: { cellWidth: 16 },
              1: { cellWidth: 16 },
              2: { cellWidth: 12 },
            },
            styles: {
              fontSize: 8,
              lineWidth: 0.5,
              lineColor: 1,
            },
            tableWidth: 44,
            startY: 175,
            margin: {
              top: 175,
              left: 110,
            },
            didParseCell(data) {
              if (data.section === 'body' && data.row.index === bestLeftTORICPPRAIndex) {
                // eslint-disable-next-line
                data.cell.styles.fillColor = [66, 186, 150];
              }
            },
          });
          pdf.autoTable({
            html: '#left-toric-astigmatism-table',
            theme: 'plain',
            columnStyles: {
              0: { cellWidth: 21 },
              1: { cellWidth: 12 },
              2: { cellWidth: 12 },
            },
            styles: {
              fontSize: 8,
              lineWidth: 0.5,
              lineColor: 1,
            },
            tableWidth: 45,
            startY: 175,
            margin: {
              top: 175,
              left: 157,
            },
          });
          if (this.iolLeftPng != null) {
            pdf.addImage(this.iolLeftPng, 'png', 152, 122, 50, 40); //  NaN, 'SLOW');
          }
        }
        const bestLeftSEIndex = this.leftSEIndex;
        pdf.autoTable({
          html: '#left-se-table',
          theme: 'plain',
          columnStyles: {
            0: { cellWidth: 20 },
            1: { cellWidth: 20 },
          },
          styles: {
            fontSize: 8,
            lineWidth: 0.5,
            lineColor: 1,
          },
          tableWidth: 40,
          startY: 120,
          margin: {
            top: 120,
            left: 110,
          },
          didParseCell(data) {
            if (data.section === 'body' && data.row.index === bestLeftSEIndex) {
              // eslint-disable-next-line
              data.cell.styles.fillColor = [66, 186, 150];
            }
          },
        });
      }
      pdf.setFontSize(10);
      pdf.text(10, 267, `Disclaimer: ${this.getDisclaimerText()}`);
      pdf.setFontSize(9);
      pdf.text(160, 284, `Copyright ©${this.currentYear} EyeLab`);
      // pdf.autoPrint({ variant: 'non-conform' });
      // pdf.output('dataurlnewwindow');
      pdf.save('HofferQST-Results.pdf');
    },
    setIOLLeftModelOptions() {
      this.input_left_IOLType = null;
    },
    getIOLLeftModelOptions() {
      const listdata = [];
      let IOLSModel = this.options_SE_IOLS;
      if (this.input_calculation_lefteye === this.options_calculation_choice[1]) { // TORIC
        IOLSModel = this.options_TORIC_IOLS;
      }
      Object.keys(IOLSModel).forEach((key) => {
        listdata.push({ value: key, text: key });
      });
      return listdata;
    },
    getIOLLeftTypeOptions() {
      const listdata = [];
      if (this.input_left_IOLModel != null) {
        let IOLSType = this.options_SE_IOLS[this.input_left_IOLModel];
        if (this.input_calculation_lefteye === this.options_calculation_choice[1]) { // TORIC
          IOLSType = this.options_TORIC_IOLS[this.input_left_IOLModel];
        }
        Object.keys(IOLSType).forEach((key) => {
          listdata.push({ value: key, text: key });
        });
      }
      return listdata;
    },
    setIOLRightModelOptions() {
      this.input_right_IOLType = null;
    },
    getIOLRightModelOptions() {
      const listdata = [];
      let IOLSModel = this.options_SE_IOLS;
      if (this.input_calculation_righteye === this.options_calculation_choice[1]) { // TORIC
        IOLSModel = this.options_TORIC_IOLS;
      }
      Object.keys(IOLSModel).forEach((key) => {
        listdata.push({ value: key, text: key });
      });
      return listdata;
    },
    getIOLRightTypeOptions() {
      const listdata = [];
      if (this.input_right_IOLModel != null) {
        let IOLSType = this.options_SE_IOLS[this.input_right_IOLModel];
        if (this.input_calculation_righteye === this.options_calculation_choice[1]) { // TORIC
          IOLSType = this.options_TORIC_IOLS[this.input_right_IOLModel];
        }
        Object.keys(IOLSType).forEach((key) => {
          listdata.push({ value: key, text: key });
        });
      }
      return listdata;
    },
    checkAL(AL) {
      if (AL > this.limitALMax) {
        return false;
      }
      return null;
    },
    sameLength(A, B) {
      const sA = Math.round(A).toString().length;
      const sB = Math.round(B).toString().length;
      if (sA === sB) return true;
      return false;
    },
    checkKFlatKSteep(KFlat, KSteep) {
      if (KSteep !== null && KFlat !== null && KSteep !== '' && KFlat !== '') {
        if (KSteep < KFlat && this.sameLength(KFlat, KSteep)) {
          // console.log('Error K2 < K1');
          return false;
        }
      }
      return null;
    },
    setLeftpACD() {
      if (this.input_calculation_lefteye === this.options_calculation_choice[1]) { // TORIC
        // eslint-disable-next-line
        this.input_left_pACD = this.options_TORIC_IOLS[this.input_left_IOLModel][this.input_left_IOLType];
      } else {
        // eslint-disable-next-line
        this.input_left_pACD = this.options_SE_IOLS[this.input_left_IOLModel][this.input_left_IOLType];
      }
    },
    setRightpACD() {
      if (this.input_calculation_righteye === this.options_calculation_choice[1]) { // TORIC
        // eslint-disable-next-line
        this.input_right_pACD = this.options_TORIC_IOLS[this.input_right_IOLModel][this.input_right_IOLType];
      } else {
        // eslint-disable-next-line
        this.input_right_pACD = this.options_SE_IOLS[this.input_right_IOLModel][this.input_right_IOLType];
      }
    },
    setRightK2Axis() {
      if (this.input_right_K1Axis < 90) {
        this.input_right_K2Axis = this.input_right_K1Axis + 90;
      } else {
        this.input_right_K2Axis = this.input_right_K1Axis - 90;
      }
    },
    setLeftK2Axis() {
      if (this.input_left_K1Axis < 90) {
        this.input_left_K2Axis = this.input_left_K1Axis + 90;
      } else {
        this.input_left_K2Axis = this.input_left_K1Axis - 90;
      }
    },
    rightCalculationChoiceChanged() {
      if (this.resultsRightAvailable === true) {
        this.resultsRightAvailable = false;
      }
      // Reset
      this.input_right_IOLModel = null;
      this.input_right_IOLType = null;
      this.input_right_pACD = null;
      this.buttonResetClick();
    },
    rightCalculationChoicePostLasikChanged() {
      // SE. For postlasik we only want SE
      this.input_calculation_righteye = 'SE';
      if (this.resultsRightAvailable === true) {
        this.resultsRightAvailable = false;
      }
      this.buttonResetClick();
    },
    leftCalculationChoiceChanged() {
      if (this.resultsLeftAvailable === true) {
        this.resultsLeftAvailable = false;
      }
      this.input_left_IOLModel = null;
      this.input_left_IOLType = null;
      this.input_left_pACD = null;
      this.buttonResetClick();
    },
    leftCalculationChoicePostLasikChanged() {
      // SE. For postlasik we only want SE
      this.input_calculation_lefteye = 'SE';
      if (this.resultsLeftAvailable === true) {
        this.resultsLeftAvailable = false;
      }
      this.buttonResetClick();
    },
    updateRightIOLSvg() {
      const a = this.$refs['iol-right-svg'];
      const svgDoc = a.contentDocument;
      const svgSelector = svgDoc.getElementById('selectorObj');
      const svgSelectorCircle = svgDoc.getElementById('circleObj');
      const svgArc = svgDoc.getElementById('arcObj');
      const svgIOLLens = svgDoc.getElementById('iolLens');
      const TargetAxis = this.output_right.TORIC[0][0].Axis[1];
      svgDoc.getElementById('resObj').innerHTML = `${TargetAxis}°`;
      svgSelector.setAttribute('transform', `rotate(-${TargetAxis},${svgSelectorCircle.cx.baseVal.value},${svgSelectorCircle.cy.baseVal.value})`);
      svgIOLLens.setAttribute('transform', `rotate(-${TargetAxis},${svgSelectorCircle.cx.baseVal.value},${svgSelectorCircle.cy.baseVal.value})`);
      svgArc.setAttribute('transform', `rotate(-${this.input_right_Meridian},${svgSelectorCircle.cx.baseVal.value},${svgSelectorCircle.cy.baseVal.value})`);
      // Important Convert -- Svg to Png Figure
      const svgData = new XMLSerializer().serializeToString(svgDoc.documentElement);
      const bsvgData = window.btoa(unescape(encodeURIComponent(svgData)));
      const image = document.createElement('img');
      const imgData = `data:image/svg+xml;base64,${bsvgData}`;
      image.setAttribute('src', imgData);
      image.onload = (() => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);
        this.iolRightPng = canvas.toDataURL('image/png');
      });
    },
    updateLeftIOLSvg() {
      const a = this.$refs['iol-left-svg'];
      const svgDoc = a.contentDocument;
      const svgSelector = svgDoc.getElementById('selectorObj');
      const svgSelectorCircle = svgDoc.getElementById('circleObj');
      const svgArc = svgDoc.getElementById('arcObj');
      const svgIOLLens = svgDoc.getElementById('iolLens');
      const TargetAxis = this.output_left.TORIC[0][0].Axis[1];
      svgDoc.getElementById('resObj').innerHTML = `${TargetAxis}°`;
      svgSelector.setAttribute('transform', `rotate(-${TargetAxis},${svgSelectorCircle.cx.baseVal.value},${svgSelectorCircle.cy.baseVal.value})`);
      svgIOLLens.setAttribute('transform', `rotate(-${TargetAxis},${svgSelectorCircle.cx.baseVal.value},${svgSelectorCircle.cy.baseVal.value})`);
      svgArc.setAttribute('transform', `rotate(-${this.input_left_Meridian},${svgSelectorCircle.cx.baseVal.value},${svgSelectorCircle.cy.baseVal.value})`);
      // Important Convert -- Svg to Png Figure
      const svgData = new XMLSerializer().serializeToString(svgDoc.documentElement);
      const bsvgData = window.btoa(unescape(encodeURIComponent(svgData)));
      const image = document.createElement('img');
      const imgData = `data:image/svg+xml;base64,${bsvgData}`;
      image.setAttribute('src', imgData);
      image.onload = (() => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);
        this.iolLeftPng = canvas.toDataURL('image/png');
      });
    },
    getRightFormData() {
      let CalculationSelected = ''; // SE - TORIC
      const formData = {};
      formData.Index = this.input_index;
      formData.Eye = 'Right';
      formData.Gender = this.input_Gender;
      formData.AL = this.input_right_AL;
      formData.ACD = this.input_right_ACD;
      formData.pACD = this.input_right_pACD;
      if (this.input_right_IOLType == null || this.input_right_IOLType === '') {
        formData.IOLName = 'Default';
      } else {
        formData.IOLName = this.input_right_IOLType;
      }
      formData.TargetRx = this.input_right_TargetRx;
      formData.LT = this.input_right_LT;
      if (this.input_calculation_righteye === this.options_calculation_choice[1]) { // TORIC
        CalculationSelected = 'TORIC';
        formData.CalculationChoice = CalculationSelected;
        formData.K2 = this.input_right_K1;
        formData.K1Axis = this.input_right_K2Axis;
        formData.K1 = this.input_right_K2;
        formData.K2Axis = this.input_right_K1Axis;
        formData.IncisionSica = this.input_right_Sica;
        formData.IncisionMeridian = this.input_right_Meridian;
      } else {
        CalculationSelected = 'SE'; // SE - TORIC
        formData.CalculationChoice = CalculationSelected;
        formData.K1 = this.input_right_K1;
        formData.K2 = this.input_right_K2;
      }
      if (this.option_calculation_right_postlasik) {
        formData.K1pre = this.input_right_K1pre;
        formData.K2pre = this.input_right_K2pre;
        formData.IOLMaster_PK1 = this.input_right_IOLMaster_PK1;
        formData.IOLMaster_PK2 = this.input_right_IOLMaster_PK2;
        formData.PosteriorR1 = this.input_right_PosteriorR1;
        formData.PosteriorR2 = this.input_right_PosteriorR2;
      }
      formData.PostLasik = this.option_calculation_right_postlasik;
      return formData;
    },
    getLeftFormData() {
      let CalculationSelected = ''; // SE - TORIC
      const formData = {};
      formData.Index = this.input_index;
      formData.Eye = 'Left';
      formData.Gender = this.input_Gender;
      formData.AL = this.input_left_AL;
      formData.ACD = this.input_left_ACD;
      if (this.input_left_IOLType == null || this.input_left_IOLType === '') {
        formData.IOLName = 'Default';
      } else {
        formData.IOLName = this.input_left_IOLType;
      }
      formData.pACD = this.input_left_pACD;
      formData.TargetRx = this.input_left_TargetRx;
      formData.LT = this.input_left_LT;
      if (this.input_calculation_lefteye === this.options_calculation_choice[1]) { // TORIC
        CalculationSelected = 'TORIC';
        formData.CalculationChoice = CalculationSelected;
        formData.K2 = this.input_left_K1;
        formData.K1Axis = this.input_left_K2Axis;
        formData.K1 = this.input_left_K2;
        formData.K2Axis = this.input_left_K1Axis;
        formData.IncisionSica = this.input_left_Sica;
        formData.IncisionMeridian = this.input_left_Meridian;
      } else {
        CalculationSelected = 'SE'; // SE - TORIC
        formData.CalculationChoice = CalculationSelected;
        formData.Eye = 'Left';
        formData.K1 = this.input_left_K1;
        formData.K2 = this.input_left_K2;
      }
      if (this.option_calculation_left_postlasik) {
        formData.K1pre = this.input_left_K1pre;
        formData.K2pre = this.input_left_K2pre;
        formData.IOLMaster_PK1 = this.input_left_IOLMaster_PK1;
        formData.IOLMaster_PK2 = this.input_left_IOLMaster_PK2;
        formData.PosteriorR1 = this.input_left_PosteriorR1;
        formData.PosteriorR2 = this.input_left_PosteriorR2;
      }
      formData.PostLasik = this.option_calculation_left_postlasik;
      return formData;
    },
    getOutputLeftSETableData() {
      const res = this.output_left.SE[0];
      const table = [];
      const bestVal = this.closest(this.input_left_TargetRx, res.ExpectedRx);
      let i;
      for (i = 0; i < res.IOL.length; i += 1) {
        let row = {
          IOL: res.IOL[i],
          Rx: res.ExpectedRx[i],
        };
        if (bestVal === res.ExpectedRx[i]) {
          this.leftSEIndex = i;
          row = {
            IOL: res.IOL[i],
            Rx: res.ExpectedRx[i],
            _rowVariant: 'success',
          };
        }
        table.push(row);
      }
      return table;
    },
    getOutputRightSETableData() {
      const res = this.output_right.SE[0];
      const table = [];
      const bestVal = this.closest(this.input_right_TargetRx, res.ExpectedRx);
      let i;
      for (i = 0; i < res.IOL.length; i += 1) {
        let row = {
          IOL: res.IOL[i],
          Rx: res.ExpectedRx[i],
        };
        if (bestVal === res.ExpectedRx[i]) {
          this.rightSEIndex = i;
          row = {
            IOL: res.IOL[i],
            Rx: res.ExpectedRx[i],
            _rowVariant: 'success',
          };
        }
        table.push(row);
      }
      return table;
    },
    getOutputRightTORICAstTableData() {
      const table = [];
      if (this.output_right.TORIC[0] != null) {
        const res = this.output_right.TORIC[0][0];
        let i;
        for (i = 0; i < res.Keys.length; i += 1) {
          table.push({ Keys: res.Keys[i], Diopters: res.Diopters[i], Axis: res.Axis[i] });
        }
      }
      return table;
    },
    getOutputLeftTORICAstTableData() {
      const table = [];
      if (this.output_left.TORIC[0] != null) {
        const res = this.output_left.TORIC[0][0];
        let i;
        for (i = 0; i < res.Keys.length; i += 1) {
          table.push({ Keys: res.Keys[i], Diopters: res.Diopters[i], Axis: res.Axis[i] });
        }
      }
      return table;
    },
    getOutputRightTORICPPRATableData() {
      const table = [];
      if (this.output_right.TORIC[0] != null) {
        const res = this.output_right.TORIC[0][1];
        const bestVal = this.closest(this.input_right_TargetRx, res.IOL_Cylinder);
        let i;
        for (i = 0; i < res.IOL_Name.length; i += 1) {
          let row = {
            IOL_Name: res.IOL_Name[i],
            IOL_Cylinder: res.IOL_Cylinder[i],
            IOL_Axis: res.IOL_Axis[i],
          };
          if (bestVal === res.IOL_Cylinder[i]) {
            this.rightTORICPPRAIndex = i;
            row = {
              IOL_Name: res.IOL_Name[i],
              IOL_Cylinder: res.IOL_Cylinder[i],
              IOL_Axis: res.IOL_Axis[i],
              _rowVariant: 'success',
            };
          }
          table.push(row);
        }
      }
      return table;
    },
    getOutputLeftTORICPPRATableData() {
      const table = [];
      if (this.output_left.TORIC[0] != null) {
        const res = this.output_left.TORIC[0][1];
        const bestVal = this.closest(this.input_left_TargetRx, res.IOL_Cylinder);
        let i;
        for (i = 0; i < res.IOL_Name.length; i += 1) {
          let row = {
            IOL_Name: res.IOL_Name[i],
            IOL_Cylinder: res.IOL_Cylinder[i],
            IOL_Axis: res.IOL_Axis[i],
          };
          if (bestVal === res.IOL_Cylinder[i]) {
            this.leftTORICPPRAIndex = i;
            row = {
              IOL_Name: res.IOL_Name[i],
              IOL_Cylinder: res.IOL_Cylinder[i],
              IOL_Axis: res.IOL_Axis[i],
              _rowVariant: 'success',
            };
          }
          table.push(row);
        }
      }
      return table;
    },
    closest(needle, haystack) {
      return haystack.reduce((a, b) => {
        const aDiff = Math.abs(a - needle);
        const bDiff = Math.abs(b - needle);
        if (aDiff === bDiff) {
          return a > b ? a : b;
        }
        return bDiff < aDiff ? b : a;
      });
    },
    resetData() {
      // Clean input
      this.resultsRightAvailable = false;
      this.resultsLeftAvailable = false;
      this.input_Surgeon = null;
      this.input_PatientName = null;
      this.input_Gender = null;
      // Right
      this.input_right_AL = null;
      this.input_right_ACD = null;
      this.input_right_K1 = null;
      this.input_right_K2 = null;
      this.input_right_pACD = null;
      this.input_right_TargetRx = 0;
      this.input_right_IOLModel = null;
      this.input_right_IOLType = null;
      this.input_right_K1pre = null;
      this.input_right_K2pre = null;
      this.input_right_IOLMaster_PK1 = null;
      this.input_right_IOLMaster_PK2 = null;
      this.input_right_PosteriorR1 = null;
      this.input_right_PosteriorR2 = null;
      this.input_right_LT = null;
      // Left
      this.input_left_AL = null;
      this.input_left_ACD = null;
      this.input_left_K1 = null;
      this.input_left_K2 = null;
      this.input_left_pACD = null;
      this.input_left_TargetRx = 0;
      this.input_left_IOLModel = null;
      this.input_left_IOLType = null;
      this.input_left_K1pre = null;
      this.input_left_K2pre = null;
      this.input_left_IOLMaster_PK1 = null;
      this.input_left_IOLMaster_PK2 = null;
      this.input_left_PosteriorR1 = null;
      this.input_left_PosteriorR2 = null;
      this.input_left_LT = null;
      // Toric Reset
      this.input_calculation_righteye = 'SE';
      this.input_calculation_lefteye = 'SE';
      this.input_left_Meridian = null;
      this.input_right_Meridian = null;
      this.input_left_Sica = null;
      this.input_right_Sica = null;
      this.input_left_K2Axis = null;
      this.input_right_K2Axis = null;
      this.input_left_K1Axis = null;
      this.input_right_K1Axis = null;
      // Results
      this.output_left = null;
      this.output_right = null;
      // Outputs Tables
      this.OutputRightSETableData = null;
      this.OutputRightTORICPPRATableData = null;
      this.OutputRightTORICAstTableData = null;
      this.OutputLeftSETableData = null;
      this.OutputLeftTORICPPRATableData = null;
      this.OutputLeftTORICAstTableData = null;
      // Images
      this.iolRightPng = null;
      this.iolLeftPng = null;
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (((this.input_calculation_righteye === this.options_calculation_choice[0])
          && (this.input_right_AL != null && this.input_right_AL !== '')
          && (this.input_right_ACD != null && this.input_right_ACD !== '')
          && (this.input_right_K1 != null && this.input_right_K1 !== '')
          && (this.input_right_K2 != null && this.input_right_K2 !== '')
          && (this.input_right_pACD != null && this.input_right_pACD !== '')
          && (this.input_right_TargetRx != null && this.input_right_TargetRx !== ''))
          || ((this.input_calculation_righteye === this.options_calculation_choice[1]) // Toric
          && (this.input_right_AL != null && this.input_right_AL !== '')
          && (this.input_right_ACD != null && this.input_right_ACD !== '')
          && (this.input_right_K1 != null && this.input_right_K1 !== '')
          && (this.input_right_K2 != null && this.input_right_K2 !== '')
          && (this.input_right_pACD != null && this.input_right_pACD !== '')
          && (this.input_right_TargetRx != null && this.input_right_TargetRx !== '')
          && (this.input_right_Meridian != null && this.input_right_Meridian !== '')
          && (this.input_right_Sica != null && this.input_right_Sica !== '')
          && (this.input_right_K1Axis != null && this.input_right_K1Axis !== '')
          && (this.input_right_K2Axis != null && this.input_right_K2Axis !== ''))) {
        this.resultsRightAvailable = false;
        axios({
          method: 'post',
          url: `${this.url}/compute`,
          data: this.getRightFormData(),
        })
          .then((response) => {
            this.output_right = response.data;
            // alert(JSON.stringify(response.data));
            // Outputs Right
            this.OutputRightSETableData = this.getOutputRightSETableData();
            this.OutputRightTORICAstTableData = this.getOutputRightTORICAstTableData();
            this.OutputRightTORICPPRATableData = this.getOutputRightTORICPPRATableData();
            this.resultsRightAvailable = true;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$bvToast.toast('Right Eye cannot be calculated because there are some null input values', {
          title: 'Right Eye',
          autoHideDelay: 2500,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      }
      if (((this.input_calculation_lefteye === this.options_calculation_choice[0])
          && (this.input_left_AL != null && this.input_left_AL !== '')
          && (this.input_left_ACD != null && this.input_left_ACD !== '')
          && (this.input_left_K1 != null && this.input_left_K1 !== '')
          && (this.input_left_K2 != null && this.input_left_K2 !== '')
          && (this.input_left_pACD != null && this.input_left_pACD !== '')
          && (this.input_left_TargetRx != null && this.input_left_TargetRx !== ''))
          || ((this.input_calculation_lefteye === this.options_calculation_choice[1]) // Toric
          && (this.input_left_AL != null && this.input_left_AL !== '')
          && (this.input_left_ACD != null && this.input_left_ACD !== '')
          && (this.input_left_K1 != null && this.input_left_K1 !== '')
          && (this.input_left_K2 != null && this.input_left_K2 !== '')
          && (this.input_left_pACD != null && this.input_left_pACD !== '')
          && (this.input_left_TargetRx != null && this.input_left_TargetRx !== '')
          && (this.input_left_Meridian != null && this.input_left_Meridian !== '')
          && (this.input_left_Sica != null && this.input_left_Sica !== '')
          && (this.input_left_K1Axis != null && this.input_left_K1Axis !== '')
          && (this.input_left_K2Axis != null && this.input_left_K2Axis !== ''))) {
        this.resultsLeftAvailable = false;
        axios({
          method: 'post',
          url: `${this.url}/compute`,
          data: this.getLeftFormData(),
        })
          .then((response) => {
            this.output_left = response.data;
            // alert(JSON.stringify(response.data));
            // Outputs Left
            this.OutputLeftSETableData = this.getOutputLeftSETableData();
            this.OutputLeftTORICAstTableData = this.getOutputLeftTORICAstTableData();
            this.OutputLeftTORICPPRATableData = this.getOutputLeftTORICPPRATableData();
            this.resultsLeftAvailable = true;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.$bvToast.toast('Left Eye cannot be calculated because there are some null input values', {
          title: 'Left Eye',
          autoHideDelay: 2500,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      }
      this.buttonResetClick();
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.resetData();
      this.buttonResetClick();
    },
  },
};
</script>
