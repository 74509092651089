<style scoped>
  .card{
    border: 1px solid #21252e;
    background-color: transparent !important;
    max-width: 500px;
    margin: 8px;
  }
  .card-body{
    padding: 2px;
    font-family: Verdana !important;
  }
  h3 {
    font-family: serif;
    font-weight: bold;
    font-size: 40px;
  }
  .custom-position{
    margin: 5px 0px 0px 3px;
    position: absolute;
    font-size: 21px;
    font-family: sans-serif;
  }
</style>
<template>
  <div id="app">
    <div id="nav">
      <b-card-group deck class="justify-content-center">
        <b-card>
          <h3><span>Hoffer</span><span class="custom-position">&#174;</span>
          <span style="margin: 0px 0px 0px 27px;">QST</span></h3>
        </b-card>
        <a href="http://eyelab.com/">
          <b-card >
            <b-img
              :src="require('@/assets/EyeLab_Logo2.jpg')"
              style="max-width: 100px;"
              height="45"
              alt="EyeLab Logo2"
            ></b-img>
            <b-img
              :src="require('@/assets/EyeLab_Logo1.jpg')"
              style="max-width: 150px;"
              height="45"
              alt="EyeLab Logo1"
            ></b-img>
          </b-card>
        </a>
        <b-card class="mt-3">
          <router-link to="/" style="font-size: 20px">Home</router-link> |
          <router-link to="/research" style="font-size: 20px">Research</router-link> |
          <router-link to="/about" style="font-size: 20px">About</router-link> |
          <router-link to="/contact" style="font-size: 20px">Contact</router-link>
        </b-card>
      </b-card-group>
    </div>
    <router-view />
    <div style="margin:20px;">
      <div>
        <b-card-group deck class="justify-content-center">
          <a href="https://iolcalculator.escrs.org/">
            <b-card class="justify-content-center">
              <b-img
                :src="require('@/assets/ESCRS_IOL_logo.png')"
                style="max-width: 100px;"
                height="45"
                alt="EyeLab Logo2"
              ></b-img>
            </b-card>
          </a>
        </b-card-group>
      </div>
      <div>
        <div style="font-size: 14px; margin: 5px; cursor: pointer; text-decoration: underline;"
          @click="showDescription = !showDescription">
          v1.5 Copyright ©{{currentYear}} EyeLab
        </div>
        <div v-if="showDescription">
          added LT for 25&lt;AL&lt;28 mm calculation in SE/Toric and improved accuracy
          for the post myopic LASIK/PRK version with optional data
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
      showDescription: false,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body {
  background-color:#21252e;
  color: white;
}

#nav {
  padding: 10px 20px 0px 20px;
}

#nav a {
  font-weight: bold;
  color: white;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
